import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import imagem from "../assets/images/especializacoes.png";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";
import { WhatsApp } from "@mui/icons-material";

export default function Especializacoes() {
	const routeToWhatsapp = () => window.open("https://wa.me/5511994049286", "_blank");
	const routeToCertificacao = () => window.open("https://certificado-labora.thinkific.com/collections/Especializa%C3%A7%C3%B5es", "_blank");
	return (
		<>
			<header>
				<Banner title="Especializações" description="Página em desenvolvimento" />
			</header>

			<div className="container-perfil pb20">
				<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box">
					<h1 className="reinvencao_explicacao pt20">Esta segunda etapa de certificações existe para que você se
						especialize nas posições mais demandadas pelo mercado.</h1>
					<img className="imagem_colmeia" src={imagem} alt="Colmeia" />
					<p className="reinvencao_explicacao pt20 pb20">Lembre-se: para começar especializações, tenha certeza de que você
						está no momento certo! Caso tenha dúvida entre em contato por whatsapp clicando abaixo:</p>
					<StyledBtn
						// className="btn-banner"
						variant="contained"
						color="success"
						onClick={routeToWhatsapp}
					>
						{<WhatsApp />}
						Whatsapp
					</StyledBtn>
					<p className="reinvencao_explicacao pt20 pb20">Comece suas especializações clicando no botão abaixo:</p>
					<StyledBtn
						variant="contained"
						color="secondary"
						onClick={routeToCertificacao}
					>
						Especializações
					</StyledBtn>
				</div>
			</div>
			<Footer />
		</>
	);
}
