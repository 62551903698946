import React, { useState } from 'react';
import { StyledBtn } from '../components/StyledButton/StyledButton.jsx';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Banner from '../components/Banner/Banner';
import MenuPerfil from "../components/MenuPerfil/MenuPerfil";
import ModalMsg from '../components/ModalMsg/ModalMsg';
import { useForm } from "react-hook-form";
import { updatePassword } from "../services/api.js";
import Footer from '../components/Footer/Footer.jsx';

function UpdatePassword() {

	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate();
	const routeToHome = () => navigate("/Home");
	const token = localStorage.getItem("token");
	const { register, handleSubmit } = useForm();

	const onSubmit = (data) => {
		if (data.nova === data.repete_nova) {
			async function updatePass() {
				await updatePassword(token, { oldPassword: data.atual, newPassword: data.nova })
					.then((response) => {
						if (response.status === 200) {
							setErrMsg("Senha atualizada com sucesso! Faça login com a nova senha!!");
						}
					})
					.catch((erro) => {
						if (erro.response.data.msg === "User and/or Password incorrect.") {
							setErrMsg("Erro: Senha atual incorreta!! Entre com as senhas novamente!!");
						}
					});
			}
			updatePass();
		} else {
			setErrMsg("Erro: Senhas diferentes!! Entre com as senhas novamente!!");
		}
	};

	return (
		<>
			<div>
				<div className="container-header">
					<header className="App-header">
						<Banner title="Alterar Senha" description="Perfil Labora Tech." />
					</header>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="container-perfil">
						<div className="container-menu">
							<MenuPerfil></MenuPerfil>
						</div>
						<div className="w75">
							<h2 className="h2-senha">Entre com as senhas:</h2>
							<Box
								sx={{ '& > :not(style)': { m: 1, width: '95%' }, }}
								noValidate
								autoComplete="off"
							>
								<TextField label="Digite a senha atual"
									type="password"
									id="senhaAtual"
									name="atual"
									variant="outlined"
									size="medium"
									{...register("atual")}
									required
								/>
								<TextField label="Digite a nova senha"
									type="password"
									id="senhaNova"
									name="nova"
									variant="outlined"
									size="medium"
									{...register("nova")}
									required
								/>
								<TextField label="Reinsira a nova senha"
									type="password"
									id="confSenhaNova"
									name="repete_nova"
									variant="outlined"
									size="medium"
									{...register("repete_nova")}
									required
								/>
							</Box>
							<div className="pt10 botoes pb150">
								<StyledBtn
									className="btn-banner"
									variant="contained"
									color="secondary"
									type="submit"
								>
									Alterar
								</StyledBtn>
								<StyledBtn
									className="btn-banner"
									variant="contained"
									color="secondary"
									onClick={routeToHome}
								>
									Cancelar
								</StyledBtn>
							</div>
							<Box>
								{errMsg === "Senha atualizada com sucesso! Faça login com a nova senha!!" && (
									<ModalMsg msg={errMsg} modalType={'success'} />
								)}
								{errMsg === "Erro: Senhas diferentes!! Entre com as senhas novamente!!" && (
									<ModalMsg msg={errMsg} modalType={'editedToHome'} />
								)}
								{errMsg === "Erro: Senha atual incorreta!! Entre com as senhas novamente!!" && (
									<ModalMsg msg={errMsg} modalType={'editedToHome'} />
								)}
							</Box>
						</div>
					</div>
				</form>
			</div >
			<Footer />
		</>
	);
}

export default UpdatePassword;