import React, { useContext, useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth";
import { StyledButton } from "../StyledButton/StyledButton";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import "./MenuPerfil.css";

export default function MenuPerfil() {

	let navigate = useNavigate();
	const routeToHome = () => navigate("/home");
	const routeToPerfil = () => navigate("/perfil");
	const routeToOportunidades = () => navigate("/oportunidades");
	const routeToUpdatePassword = () => navigate("/updatePassword");
	const routeToCertificacao = () => window.open("https://certificado-labora.thinkific.com/", "_blank");
	const routeToJornadaLabora = () => navigate("/jornadaLabora");
	const routeToProcSeletivos = () => navigate("/procSeletivos");
	const routeToJornadasRealizadas = () => navigate("/jornadasRealizadas");
	const routeToDisponibilidade = () => navigate("/disponibilidade");
	const routeToLab60 = () => window.open("https://www.lab60.org/", "_blank");
	const routeToMei = () => navigate("/mei");
	const routeToRoleplays = () => navigate("/roleplays");
	const routeToRanking = () => navigate("/ranking");
	const routeToAgenda = () => navigate("/agenda");
	const routeToAppLabora = () => navigate("/appLabora");
	const routeToOnboarding = () => navigate("/onboarding");
	const routeToDashboard = () => navigate("/dashboard");
	const routeToFinanceiro = () => navigate("/financeiro");
	const routeToReskiling = () => navigate("/reskiling");
	const routeToFlexLabora = () => navigate("/flexLabora");
	const routeToEventos = () => navigate("/eventos");
	const routeToMoedasLabora = () => navigate("/moedasLabora");
	const routeToHubLabora = () => navigate("/hubLabora");
	const routeToFormatura = () => navigate("/formatura");
	const routeToBemVindos = () => navigate("/bemVindos");
	const routeToMentorias = () => navigate("/mentorias");
	const routeToAdvocacy = () => navigate("/advocacy");
	const routeToCulturaLabora = () => navigate("/culturaLabora");
	const routeToEspecializacoes = () => navigate("/especializacoes");
	const routeToTrabalhoFlex = () => navigate("/trabalhoFlex");
	const routeToComecePorAqui = () => navigate("/comecePorAqui");

	const { logout } = useContext(AuthContext);
	const handleLogout = () => {
		logout();
	};

	const [open1, setOpen1] = useState(false);
	const toggleMenu1 = () => {
		setOpen1(!open1);
		setOpen2(false);
		setOpen3(false);
		setOpen4(false);
		setOpen5(false);
	};
	const handleClick1 = () => {
		toggleMenu1();
	};

	const [open2, setOpen2] = useState(false);
	const toggleMenu2 = () => {
		setOpen2(!open2);
		setOpen1(false);
		setOpen3(false);
		setOpen4(false);
		setOpen5(false);
	};
	const handleClick2 = () => {
		toggleMenu2();
	};

	const [open3, setOpen3] = useState(false);
	const toggleMenu3 = () => {
		setOpen3(!open3);
		setOpen1(false);
		setOpen2(false);
		setOpen4(false);
		setOpen5(false);
	};
	const handleClick3 = () => {
		toggleMenu3();
	};

	const [open4, setOpen4] = useState(false);
	const toggleMenu4 = () => {
		setOpen4(!open4);
		setOpen1(false);
		setOpen2(false);
		setOpen3(false);
		setOpen5(false);
	};
	const handleClick4 = () => {
		toggleMenu4();
	};

	const [open5, setOpen5] = useState(false);
	const toggleMenu5 = () => {
		setOpen5(!open5);
		setOpen1(false);
		setOpen2(false);
		setOpen3(false);
		setOpen4(false);
	};
	const handleClick5 = () => {
		toggleMenu5();
	};

	return (
		<>
			<Box sx={{ display: "flex", "& > *": { mt: 2, ml: 4 } }}>
				<ButtonGroup
					orientation="vertical"
					aria-label="vertical contained button group"
					variant="contained"
					color="secondary"
					size="small"
				>
					<StyledButton color="secondary" onClick={routeToHome} key="Home">
						Plataforma Labora
					</StyledButton>
					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={open1 ? "third" : "secondary"}
							aria-controls={open1 ? "basic-menu" : undefined}
							aria-expanded={open1 ? "true" : undefined}
							aria-haspopup="true"
							onClick={handleClick1}
						>
							Reskilling
							{open1 ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						<div class={`submenu ${open1 ? 'open' : ''}`}>
							<ul>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToComecePorAqui}>Comece por aqui</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToJornadaLabora}>Meus Certificados</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToMei}>Aprenda como abrir uma MEI</StyledButton> </li>
							</ul>
						</div>
					</div>
					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={open2 ? "third" : "secondary"}
							// aria-controls={open2 ? "basic-menu" : undefined}
							// aria-expanded={open2 ? "true" : undefined}
							// aria-haspopup="true"
							onClick={handleClick2}
						>
							Back 2 the Game
							{open2 ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						<div class={`submenu ${open2 ? 'open' : ''}`}>
							<ul>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToEspecializacoes}>Especializações</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToOportunidades}>Oportunidades</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToAgenda}>Agenda de eventos</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToRoleplays}>Roleplays / Avaliações</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToRanking}>Ranking</StyledButton> </li>
							</ul>
						</div>
					</div>
					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={open3 ? "third" : "secondary"}
							aria-controls={open3 ? "basic-menu" : undefined}
							aria-expanded={open3 ? "true" : undefined}
							aria-haspopup="true"
							onClick={handleClick3}
						>
							Flex Labora
							{open3 ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						<div class={`submenu ${open3 ? 'open' : ''}`}>
							<ul>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToTrabalhoFlex}>Trabalho Flex</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToDisponibilidade}>Disponibilidade</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToAppLabora}>Baixe o app</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToOnboarding}>Onboarding dos projetos</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToDashboard}>Dashboard do projeto</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToJornadasRealizadas}>Minha jornada</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToFinanceiro}>Meu financeiro</StyledButton> </li>
							</ul>
						</div>
					</div>
					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={open4 ? "third" : "secondary"}
							aria-controls={open4 ? "basic-menu" : undefined}
							aria-expanded={open4 ? "true" : undefined}
							aria-haspopup="true"
							onClick={handleClick4}
						>
							Eu na Comunidade
							{open4 ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						<div class={`submenu ${open4 ? 'open' : ''}`}>
							<ul>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToReskiling}>Reskiling</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToProcSeletivos}>Processos Seletivos</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToFlexLabora}>Flex Labora</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToPerfil}>Meu perfil</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToUpdatePassword}>Alterar senha</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToCulturaLabora}>Cultura Labora</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToEventos}>Eventos</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToMoedasLabora}>Moedas Labora</StyledButton> </li>
							</ul>
						</div>
					</div>
					<div className="menu-animado">
						<StyledButton sx={{ "border-radius": "0px" }}
							className="menu-button"
							color={open5 ? "third" : "secondary"}
							aria-controls={open5 ? "basic-menu" : undefined}
							aria-expanded={open5 ? "true" : undefined}
							aria-haspopup="true"
							onClick={handleClick5}
						>
							Encontros da rede
							{open5 ? <ArrowDropUp /> : <ArrowDropDown />}
						</StyledButton>
						<div class={`submenu ${open5 ? 'open' : ''}`}>
							<ul>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToLab60}>Lab60+</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToHubLabora}>Hub Labora</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToFormatura}>Formatura</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToBemVindos}>Bem vindos</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToMentorias}>Mentorias</StyledButton> </li>
								<li><StyledButton sx={{ width: "100%", opacity: "0.7" }} color={"third"} onClick={routeToAdvocacy}>Advocacy</StyledButton> </li>
							</ul>
						</div>
					</div>
					<StyledButton color="secondary" onClick={handleLogout} key="Sair">
						Sair
						{/* <LogoutTwoTone/> */}
					</StyledButton>
				</ButtonGroup>
			</Box>
		</>
	);
}
