import React, { useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from "../contexts/auth";
import { UserProvider } from "../contexts/userUpdate";
import Home from "../pages/Home";
import Perfil from "../pages/Perfil.jsx";
import Oportunidades from "../pages/Oportunidades.jsx";
import Login from "../pages/Login";
import PasswordReset from "../pages/PasswordReset";
import Cadastro from "../pages/Cadastro";
import NoPage from "../pages/NoPage";
import UpdatePassword from "../pages/UpdatePassword";
import UpdateTempPass from "../pages/UpdateTempPass";
import JornadaLabora from "../pages/JornadaLabora";
import ProcSeletivos from "../pages/ProcSeletivos";
import JornadasRealizadas from "../pages/JornadasRealizadas";
import Disponibilidade from "../pages/Disponibilidade.jsx";
import Mei from "../pages/Mei.jsx";
import Roleplays from "../pages/Roleplays.jsx";
import Ranking from "../pages/Ranking.jsx";
import Agenda from "../pages/Agenda.jsx";
import AppLabora from "../pages/AppLabora.jsx";
import Onboarding from "../pages/Onboarding.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import Financeiro from "../pages/Financeiro.jsx";
import Reskiling from "../pages/Reskiling.jsx";
import FlexLabora from "../pages/FlexLabora.jsx";
import Eventos from "../pages/Eventos.jsx";
import MoedasLabora from "../pages/MoedasLabora.jsx";
import HubLabora from "../pages/HubLabora.jsx";
import Formatura from "../pages/Formatura.jsx";
import BemVindos from "../pages/BemVindos.jsx";
import Mentorias from "../pages/Mentorias.jsx";
import Advocacy from "../pages/Advocacy.jsx";
import CulturaLabora from "../pages/CulturaLabora.jsx";
import Especializacoes from "../pages/Especializacoes.jsx";
import TrabalhoFlex from "../pages/TrabalhoFlex.jsx";
import ComecePorAqui from "../pages/ComecePorAqui.jsx";
// import Empresas from "../pages/Empresas";
// import Certificados from "../pages/Certificados";

const Rotas = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);
    if (loading) {
      return <div className="loading"> Carregando... </div>;
    }
        
    if (!authenticated) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  };
  return (
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <Routes>
            <Route exact path="/home" element={ 
              <Private>
                <Home />
              </Private>
              }
            />
            <Route path="/perfil" element={
              <Private>
                <Perfil />
              </Private>
              }
            />
            <Route
              path="/oportunidades" element={
                <Private>
                  <Oportunidades />
                </Private>
              }
            />
            <Route
              path="/updatePassword" element={
                <Private>
                  <UpdatePassword />
                </Private>
              }
            />
            <Route
              path="/updateTempPass" element={
                <Private>
                  <UpdateTempPass />
                </Private>
              }
            />
            <Route
              path="/disponibilidade" element={
                <Private>
                  <Disponibilidade />
                </Private>
              }
            />
            <Route
              path="/jornadaLabora" element={
                <Private>
                  <JornadaLabora />
                </Private>
              }
            />
            <Route
              path="/procSeletivos" element={
                <Private>
                  <ProcSeletivos />
                </Private>
              }
            />
            <Route
              path="/jornadasRealizadas" element={
                <Private>
                  <JornadasRealizadas />
                </Private>
              }
            />
            <Route
              path="/mei" element={
                <Private>
                  <Mei />
                </Private>
              }
            />
            <Route
              path="/roleplays" element={
                <Private>
                  <Roleplays />
                </Private>
              }
            />
            <Route
              path="/ranking" element={
                <Private>
                  <Ranking />
                </Private>
              }
            />
            <Route
              path="/agenda" element={
                <Private>
                  <Agenda />
                </Private>
              }
            />
            <Route
              path="/appLabora" element={
                <Private>
                  <AppLabora />
                </Private>
              }
            />
            <Route
              path="/onboarding" element={
                <Private>
                  <Onboarding />
                </Private>
              }
            />
            <Route
              path="/dashboard" element={
                <Private>
                  <Dashboard />
                </Private>
              }
            />
            <Route
              path="/Financeiro" element={
                <Private>
                  <Financeiro />
                </Private>
              }
            />
            <Route
              path="/reskiling" element={
                <Private>
                  <Reskiling />
                </Private>
              }
            />
            <Route
              path="/flexLabora" element={
                <Private>
                  <FlexLabora />
                </Private>
              }
            />
            <Route
              path="/eventos" element={
                <Private>
                  <Eventos />
                </Private>
              }
            />
            <Route
              path="/moedasLabora" element={
                <Private>
                  <MoedasLabora />
                </Private>
              }
            />
            <Route
              path="/hubLabora" element={
                <Private>
                  <HubLabora />
                </Private>
              }
            />
            <Route
              path="/formatura" element={
                <Private>
                  <Formatura />
                </Private>
              }
            />
            <Route
              path="/bemVindos" element={
                <Private>
                  <BemVindos />
                </Private>
              }
            />
            <Route
              path="/mentorias" element={
                <Private>
                  <Mentorias />
                </Private>
              }
            />
            <Route
              path="/advocacy" element={
                <Private>
                  <Advocacy />
                </Private>
              }
            />
            <Route
              path="/culturaLabora" element={
                <Private>
                  <CulturaLabora />
                </Private>
              }
            />
            <Route
              path="/especializacoes" element={
                <Private>
                  <Especializacoes />
                </Private>
              }
            />
            <Route
              path="/trabalhoFlex" element={
                <Private>
                  <TrabalhoFlex />
                </Private>
              }
            />
            <Route
              path="/comecePorAqui" element={
                <Private>
                  <ComecePorAqui />
                </Private>
              }
            />
            {/* <Route
              path="/empresas" element={
                <Private>
                  <Empresas />
                </Private>
              }
            /> */}
            {/* <Route
              path="/certificados" element={
                <Private>
                  <Certificados />
                </Private>
              }
            /> */}
            {" "}

            <Route path="*" element={<NoPage />} />{" "}
            <Route exact path="/" element={<Login />} />{" "}
            <Route path="/passwordReset" element={<PasswordReset />} />{" "}
            <Route path="/cadastro" element={<Cadastro />} />{" "}
            {/* <Route path="*" element={<NoPage />} />{" "} */}
          </Routes>{" "}
        </UserProvider>{" "}
      </AuthProvider>{" "}
    </BrowserRouter>
  );
};

export default Rotas;
