import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";

export default function Mentorias() {
	return (
		<>
			<header>
				<Banner title="Mentorias" description="Página em desenvolvimento" />
			</header>

			<div className="container-perfil">
				<div className="container-menu pb150">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box pb150 emBreve">
					<h1>Em breve...</h1>
				</div>
			</div>
			<Footer />
		</>
	);
}
