import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";

export default function Mei() {
	return (
		<>
			<header>
				<Banner title="Como abrir uma MEI" description="Página em desenvolvimento" />
			</header>

			<div className="container-perfil">
				<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box">
					<h1 className="reinvencao_explicacao pt20">Se você chegou até aqui, é porque foi alocado em algum projeto
						da Labora, já passou pelas jornadas de treinamento, e agora se tornará um profissional associado.</h1>
					<p className="reinvencao_explicacao">Para atuar como Flex Labora (profissional associado), você precisará
						abrir uma MEI (Microempreendedor Individual). Este procedimento deve ser realizado através do site
						Portal do Empreendedor (<a href="https://mei.receita.economia.gov.br/inscricao/login" target="_blank"
							rel="noreferrer">https://mei.receita.economia.gov.br/inscricao/login</a>).</p>
					<p className="reinvencao_explicacao">Basta clicar em “Formalize-se”, preencher o cadastro com seus dados
						e, no campo de atividade, selecionar os CNAES 8219-9/99 e/ou 7319-0/02. Conclua o seu cadastro.</p>
					<p className="reinvencao_explicacao">Após a abertura, a única taxa a ser recolhida é o DAS, que deve ser
						paga mensalmente e tem um valor aproximado de R$75,00 (o valor pode variar de acordo com o seu estado).</p>
					<p className="reinvencao_explicacao">Envie seus dados para cadastro e criação de contrato no link:<span> </span>
						<a href="https://app.pipefy.com/public/form/-vI4rAD1" target="_blank" rel="noreferrer">
							https://app.pipefy.com/public/form/-vI4rAD1</a>.</p>
					<p className="reinvencao_explicacao">Confira e assine o contrato.</p>
					<p className="reinvencao_explicacao">Lembre-se de se cadastrar na plataforma Labora por este link, caso ainda
						não seja cadastrado:<span> </span><a href="https://w3.labora.tech/" target="_blank" rel="noreferrer">https://w3.labora.tech/</a>
						. Complete seu perfil e aceite a política de privacidade da Labora.</p>
					<p className="reinvencao_explicacao">Instale o APP Labora para facilitar seu embarque e desembarque.
						Embarques e desembarques estarão disponíveis 15 minutos antes e 15 minutos depois do horário marcado.</p>
				</div>
			</div>
			<Footer />
		</>
	);
}
