import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from '../components/Banner/Banner.jsx';
import MenuPerfil from '../components/MenuPerfil/MenuPerfil.jsx';
import { Box, TextField } from '@mui/material';
import { updateTempPassword } from '../services/api.js';
import ModalMsg from '../components/ModalMsg/ModalMsg.jsx';
import { useForm } from "react-hook-form";
import { StyledBtn } from '../components/StyledButton/StyledButton.jsx';

function UpdateTempPass() {
	const [errMsg, setErrMsg] = useState("");
	const navigate = useNavigate();
	const routeToHome = () => navigate("/Home");
	const token = localStorage.getItem("token");
	//console.log("=> Pagina: Alterar senha temporaria.");
	//console.log("token da pagina de updateTempPass", token);

	const { register, handleSubmit } = useForm();

	const onSubmit = (data) => {

		if ((data.nova === "") || (data.repete_nova === "")) {
			setErrMsg("Erro: Senha(s) em branco, preencha os campos de senha!");
		}

		if (data.nova === data.repete_nova) {
			async function updatePassTemp() {
				await updateTempPassword(token, { newPassword: data.nova })
					.then((response) => {
						if (response.status === 200) {
							setErrMsg("Senha atualizada com sucesso! Faça login com a nova senha!!");
						} else { setErrMsg("Erro: Erro no servidor!!") }
					});
			};
			updatePassTemp();
		} else {
			setErrMsg("Erro: Senhas diferentes!! Entre com a senha temporária novamente!!");
		}

	};

	return (
		<>
			<div>
				<div className="container-header">
					<header className="App-header">
						<Banner title="Alterar Senha" description="Perfil Labora Tech." />
					</header>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="container-perfil">
						<div className="container-menu">
							<MenuPerfil></MenuPerfil>
						</div>
						<div className="w75">
							<h2 className="h2-senha">Entre com a nova senha:</h2>
							<Box
								sx={{ '& > :not(style)': { m: 1, width: '95%' }, }}
								noValidate
								autoComplete="off"
							>
								<TextField label="Digite a nova senha"
									type="password"
									id="password"
									name="nova"
									variant="outlined"
									size="medium"
									{...register("nova")}
									required
								/>
								<TextField label="Reinsira a nova senha"
									type="password"
									id="confSenhaNova"
									name="repete_nova"
									variant="outlined"
									size="medium"
									{...register("repete_nova")}
									required
								/>
							</Box>
							<div className="pt10 botoes">
								<StyledBtn
									className="btn-banner"
									variant="contained"
									color="secondary"
									type="submit"
								>
									Alterar
								</StyledBtn>
								<StyledBtn
									className="btn-banner"
									variant="contained"
									color="secondary"
									onClick={routeToHome}
								>
									Cancelar
								</StyledBtn>
							</div>
							<Box>
								{errMsg === "Senha atualizada com sucesso! Faça login com a nova senha!!" && (
									<ModalMsg msg={errMsg} modalType={'success'} />
								)}
								{errMsg === "Erro: Senhas diferentes!! Entre com a senha temporária novamente!!" && (
									<ModalMsg msg={errMsg} modalType={'success'} />
								)}
								{errMsg === "Erro: Senha(s) em branco, preencha os campos de senha!" && (
									<ModalMsg msg={errMsg} modalType={'success'} />
								)}
							</Box>
						</div>
					</div>
				</form>
			</div >
		</>
	);
}

export default UpdateTempPass;
