import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";
import imagem from "../assets/images/flexLabora.png";
import { WhatsApp } from "@mui/icons-material";
import { StyledBtn } from "../components/StyledButton/StyledButton.jsx";

export default function TrabalhoFlex() {
	const routeToWhatsapp = () => window.open("https://wa.me/5511994049286", "_blank");
	return (
		<>
			<header>
				<Banner title="Trabalho Flex" description="Página em desenvolvimento" />
			</header>

			<div className="container-perfil pb20">
				<div className="container-menu">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box">
					<h1 className="reinvencao_explicacao pt20">Agora que você já se especializou em nossas certificações para
						as posições mais demandadas do mercado está na hora de começar a operar via trabalho flex.</h1>
					<p className="reinvencao_explicacao">O que é o trabalho flex?</p>
					<p className="reinvencao_explicacao">A tecnologia Labora Flex acelera o crescimento das empresas, a partir
						da disponibilização de inteligência de crescimento e “squads as a service” formados por talentos experientes
						e intergeracionais em constante desenvolvimento.</p>
					<p className="reinvencao_explicacao">De acordo com o nosso relacionamento e entendimento das suas potências
						é a hora de conhecer os projetos que possivelmente vamos trabalhar. Clique em disponibilidade no menu Flex
						Labora para dar o próximo passo.</p>
					<img className="imagem_colmeia pb20" src={imagem} alt="Colmeia" />
					<p className="reinvencao_explicacao pt20 pb20">Caso tenha dúvida entre em contato por whatsapp clicando abaixo:</p>
					<StyledBtn
						// className="btn-banner"
						variant="contained"
						color="success"
						onClick={routeToWhatsapp}
					>
						{<WhatsApp />}
						Whatsapp
					</StyledBtn>
				</div>
			</div>
			<Footer />
		</>
	);
}
