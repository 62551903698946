import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const StyledButton = styled(Button)({
    textTransform: "none",
    fontFamily: "Quicksand",
    fontSize: 16,
    borderRadius: 0,
});

export const StyledBtn = styled(Button)({
    textTransform: "none",
    fontFamily: "Quicksand",
    fontSize: 16,
    borderRadius: 20,
});
