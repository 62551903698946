import React from "react";
import Banner from "../components/Banner/Banner.jsx";
import Footer from "../components/Footer/Footer.jsx";
import "./PagesStyles.css";
import MenuPerfil from "../components/MenuPerfil/MenuPerfil.jsx";

export default function Onboarding() {
	return (
		<>
			<header>
				<Banner title="Onboarding dos projetos" description="Página em desenvolvimento" />
			</header>

			<div className="container-perfil">
				<div className="container-menu pb150">
					<MenuPerfil></MenuPerfil>
				</div>
				<div className="container-box pb150">
					<h1 className="reinvencao_explicacao pt60">Aqui você encontrará todas as informações e materiais para entender
						o projeto no qual você  foi alocado e estar apto a operar via trabalho flex.</h1>
					<p className="reinvencao_explicacao">Mais detalhes em breve… </p>
				</div>
			</div>
			<Footer />
		</>
	);
}
